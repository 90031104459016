var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("background-color: #f8f8f4;; overflow: hidden; height: 100%;" + _vm.styleCard))},[_c('b-row',{staticStyle:{"margin":"0px"},attrs:{"align-v":"center","align-h":"center"}},[_c('b-col',{staticStyle:{"padding":"0px"},attrs:{"lg":_vm.col,"md":"12"}},[_c('div',{class:("card-full flex-row" + (_vm.reverse ? '-reverse' : ''))},[_c('div',{staticClass:"card-text invisible",attrs:{"id":("card-full-" + (_vm.reverse ? 'right' : 'left') + "-" + (_vm.dataList.header[0].text.replaceAll(' ', '-')))}},[_c('h2',[_c('span',{staticClass:"border-red tepa-h2"},[_vm._v(" "+_vm._s(_vm.dataList.header[0].text)+" ")])]),_c('div',{staticClass:"section"},[_c('prismic-rich-text',{staticClass:"tepa-p",attrs:{"field":_vm.dataList.info}})],1),(_vm.dataList.readmore)?_c('span',{staticClass:"section-link invisible",attrs:{"id":("card-full-readmore-" + (_vm.dataList.header[0].text.replaceAll(
              ' ',
              '-'
            )))},on:{"click":function($event){return _vm.toPage(_vm.dataList.link[0].text)}}},[_vm._v("+ Read more")]):_vm._e()]),_c('div',{staticClass:"img-card-full invisible",style:({
            backgroundImage: ("url(" + (_vm.dataList.img.url) + ")"),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: (_vm.imgHeight + "px"),
            width: '100%',
          }),attrs:{"id":("card-full-" + (_vm.reverse ? 'left' : 'right') + "-" + (_vm.dataList.header[0].text.replaceAll(' ', '-')))}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    :style="`background-color: #f8f8f4;; overflow: hidden; height: 100%;${styleCard}`"
  >
    <b-row align-v="center" align-h="center" style="margin: 0px">
      <b-col :lg="col" md="12" style="padding: 0px">
        <div :class="`card-full flex-row${reverse ? '-reverse' : ''}`">
          <div
            class="card-text invisible"
            :id="`card-full-${
              reverse ? 'right' : 'left'
            }-${dataList.header[0].text.replaceAll(' ', '-')}`"
          >
            <h2>
              <span class="border-red tepa-h2">
                {{ dataList.header[0].text }}
              </span>
            </h2>
            <div class="section">
              <prismic-rich-text class="tepa-p" :field="dataList.info" />
            </div>
            <span
              v-if="dataList.readmore"
              :id="`card-full-readmore-${dataList.header[0].text.replaceAll(
                ' ',
                '-'
              )}`"
              class="section-link invisible"
              @click="toPage(dataList.link[0].text)"
              >+ Read more</span
            >
          </div>
          <div
            :id="`card-full-${
              reverse ? 'left' : 'right'
            }-${dataList.header[0].text.replaceAll(' ', '-')}`"
            class="img-card-full invisible"
            :style="{
              backgroundImage: `url(${dataList.img.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: `${imgHeight}px`,
              width: '100%',
            }"
          ></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: ["dataList", "styleCard", "reverse", "col", "imgHeight"],
  mounted() {
    const me = this;
    const id = me.dataList.header[0].text.replaceAll(" ", "-");
    console.log(me.dataList.readmore);
    $(document).scroll(function () {
      const height = $(window).scrollTop() + $(window).height();
      // console.log(height, $(`#card-full-${id}`).offset().top);
      if ($(`#card-full-left-${id}`).length > 0) {
        if (height > $(`#card-full-left-${id}`).offset().top) {
          $(`#card-full-left-${id}`)
            .addClass("animate__animated animate__fadeInRight")
            .removeClass("invisible");
        }
        if (height > $(`#card-full-right-${id}`).offset().top) {
          $(`#card-full-right-${id}`)
            .addClass("animate__animated animate__fadeInLeft")
            .removeClass("invisible");
        }
        if (
          me.dataList.readmore &&
          height > $(`#card-full-readmore-${id}`).offset().top
        ) {
          $(`#card-full-readmore-${id}`)
            .addClass("animate__animated animate__flipInX")
            .removeClass("invisible");
        }
      }
    });
  },
  methods: {
    toPage(url) {
      if (url.startsWith("http")) {
        let win = window.open(url, "_blank");
        win.focus();
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped>
.tepa-p {
  text-align: center;
}
.card-info {
  /* margin: 90px 0; */
  padding: 90px 0;
}
.section {
  margin: 30px 0 25px;
}
.section-link {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #740b1b;
}
.card-text {
  /* min-width: 500px;
  width: 500px;
  max-width: 500px; */
  min-width: 580px;
  width: 50%;
  padding: 0 81px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.card-full {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tepa-h2 {
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 2px solid;
  text-align: center;
}
@media only screen and (max-width: 992px) {
  .section {
    margin: 10px 0 0px;
  }
  .img-card-full {
    width: 100% !important;
    height: 256px !important;
  }
  .card-full {
    flex-direction: column !important;
  }
  .img-card-full {
    order: 1;
  }
  .card-text {
    order: 2;
    padding: 40px;
    min-width: 0;
    max-width: unset;
    width: 100%;
  }
  .card-info {
    padding: 0px;
    margin: 30px 0 40px;
  }
}
</style>